<template>
    <div id="unsubscribe">
        <div v-if="loading" class="">
            Loading
        </div>
        <div v-else-if="!error">
            You have unsubscribed from the alert.
        </div>
        <div v-else>
            Encountered an error, try again later.
        </div>
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
export default {
    name: "ManageSubscriptions",
    data(){
        return{
            apiURL,
            error:false,
            loading: true,
        }
    },
    metaInfo: {
        title: 'Unsubscribe',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    mounted(){
        this.unsubscribe();
    },
    methods: {
        async unsubscribe() {
            try {
                await this.axios.put(`${apiURL}/api/users/unsubscribe/${this.$route.params.id}`);
            } catch(e) {
                this.error = true;
            } 
            this.loading = false;
        },
    }
}
</script>

<style>
#unsubscribe{
    text-align: center;
    padding: 60px 0;
}
</style>